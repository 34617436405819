<template>
  <div>
    <section-banner :model="section"></section-banner>
    <h6 class="text-caption text-center mt-n12"></h6>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-container class="mt-12 mb-10" style="background-color:#f6f6f6;">
      <v-row>
        <v-col>
          <table>
            <tr>
              <td class="px-2" style="width:75px;"><strong>Step 1:</strong></td>
              <td>Make sure you have all the information you need to complete the document you want (see the “Required Information” section for each document)</td>
            </tr>
            <tr>
              <td class="px-2" style="width:75px;"><strong>Step 2:</strong></td>
              <td>Settle down with the beverage of your choice, select the DIY Document you want, and breeze through our check-out process</td>
            </tr>
            <tr>
              <td class="px-2" style="width:75px;"><strong>Step 3:</strong></td>
              <td>Complete the questionnaire with the information you’ve already got together (it should take you no longer than the time indicated under “Time taken to complete document” for each document)</td>
            </tr>
            <tr>
              <td class="px-2" style="width:75px;"><strong>Step 4:</strong></td>
              <td>Click on “Assemble” once you’ve completed all the questions and your DIY Document will immediately be ready for download.</td>
            </tr>
          </table>
        </v-col>
        <v-col class="text-center">
          <video width="420" height="240" controls>
            <source src="/prodvid.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-12 mb-12">
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="6" sm="12" xs="12" v-for="(product, i) in products.records" :key="i" class="d-flex" style="flex-direction:column">
          <product-card class=" flex-grow-1" :product="product" @showLogin="showLogin" @addProduct="addProductToCart"></product-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-banner elevation="3">
        <h3 class="primary__text text-center">
          Please take note of our disclaimers before purchasing our DIY DOCUMENTS. You can view them
          <a href="/policies-disclaimers" target="_blank">HERE</a>
        </h3>
      </v-banner>
    </v-container>
    <v-dialog width="750" v-model="showLoginState" @click:outside="showLoginState = false">
      <login-register @addToCart="closeDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import SectionBanner from "@/components/Frontend/SectionBanner";
import ProductCard from "@/components/Frontend/Commerce/ProductCard";
import LoginRegister from "@/components/Frontend/LoginRegister";

export default {
  components: {
    SectionBanner,
    ProductCard,
    LoginRegister,
  },
  data() {
    return {
      showLoginState: false,
      products: [],
      totalRecords: 0,
      isLoading: false,
      productToAdd: "",
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      section: {
        BannerSmall: true,
        Title: "DIY Documents",
        Subtitle: "DIY Legal documents and packages to suit your needs.",
        HTMLDescription: "Select a DIY Document below.</li></ul>",
        HTMLCaption: "New documents will be added weekly.",
        img: require("@/assets/img/background.jpg"),
      },
    };
  },
  created() {
    this.$eventHub.$on("addProductAfterLogin", this.addProductAfterLogin);
    console.log(this.products);
  },
  beforeDestroy() {
    this.$eventHub.$off("addProductAfterLogin");
  },
  apollo: {
    products: {
      query: gql`
        query Products($limit: Int!, $skip: Int!, $query: JSON!) {
          products(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              title
              content
              featurePicture
              previewDocument
              completionTime
              requiredInfo
              category
              price
              tagIDs
              disclaimer
              tags {
                id
                name
                type
              }
              digitalItem
              createdBy
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      variables() {
        const self = this;
        const limit = self.options.itemsPerPage;
        const skip = (self.options.page - 1) * limit;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {
    addProductToCart(id) {
      this.$eventHub.$emit(
        "addProductToCart",
        this.products.records.find((product) => product.id == id)
      );
    },
    showLogin(obj) {
      if (obj.state) {
        this.productToAdd = obj.productId;
        this.showLoginState = obj.state;
      }
    },
    closeDialog() {
      this.showLoginState = false;
    },
    addProductAfterLogin() {
      const self = this;
      this.$eventHub.$emit(
        "addProductToCartAfterLogin",
        self.products.records.find((product) => product.id == self.productToAdd)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}
</style>
